import React, { useEffect, useState } from "react";

const OuruniverseBody = () => {
  return (
    <>
      <>
        <section className="topMargin">
          <div className="why-chhose-us-area ">
            <div className="container ">
              <div className="row ">
                <div className="col-md-12">
                  <div className="row">
                    <div
                      className="col-md-12  wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="single-about">
                        <div className="section-title">
                          <h2>Who We Are</h2>
                        </div>
                        <p>
                          PEN Software is more than a Technology Provider; we
                          are your partners in innovation and growth. Our
                          boutique powerhouse firm excels in:
                        </p>

                        <div className="single-about">
                          {/* <div className="about-icon">
                    <span className="lnr lnr-pie-chart" />
                  </div> */}
                          {/* <p>Our Boutique Powerhouse Firm excels in:</p> */}
                          <h4 style={{ fontWeight: "bold" }}>
                            Innovative Technology Consulting:
                          </h4>
                          <p>
                            Delivering tailored solutions and strategic
                            outsourcing to meet your specific project
                            requirements.
                          </p>
                        </div>
                        <div className="single-about">
                          {/* <div className="about-icon">
                    <span className="lnr lnr-crop" />
                  </div> */}
                          <h4 style={{ fontWeight: "bold" }}>
                            Flexible Staffing Augmentation:
                          </h4>
                          <p>
                            {" "}
                            Providing top-tier talent locally in North America
                            and globally in India, supporting your team wherever
                            you operate.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="topMargin">
          <div className="why-chhose-us-area">
            <div className="container">
              <div className="row">
                <div class="col-sm-12">
                  <div className="section-title section1">
                    <h2>The PEN Difference – Why Choose PEN?</h2>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4">
                  <div className="why-image">
                    <img src="/assets/images/why (4).jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div
                      className="col-md-12  wow fadeInUp"
                      data-wow-delay=".2s"
                    ></div>
                    <div
                      className="col-md-12  wow fadeInUp"
                      data-wow-delay=".4s"
                    >
                      <div className="single-about">
                        <h4 style={{ fontWeight: "bold" }}>
                          Exceptional Experience:{" "}
                        </h4>
                        <p>
                          Delivering a positive experience to customers and
                          candidates in every interaction defines who we are and
                          what we stand for.
                        </p>
                      </div>
                      <div className="single-about">
                        <h4 style={{ fontWeight: "bold" }}>
                          {" "}
                          Unmatched and Diverse Expertise:{" "}
                        </h4>
                        <p>
                          Our team seamlessly integrates advanced technological
                          skills with exceptional recruiting capabilities.
                        </p>
                      </div>
                      <div className="single-about">
                        <h4 style={{ fontWeight: "bold" }}>
                          Partnership Focus:{" "}
                        </h4>
                        <p>
                          We cultivate long-term relationships that drive your
                          business success
                        </p>
                      </div>
                      <div className="single-about">
                        <h4 style={{ fontWeight: "bold" }}>Results Driven: </h4>
                        <p>
                          We deliver measurable outcomes that align with your
                          goals, ensuring precision, efficiency, and agility in
                          every project
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
    <div className="system section-padding">
      <div className="row">
        <div className="col-lg-7">
          <div className="system-bg ">
          
            <div
              className="system-single wow fadeIn"
              data-wow-duration="1.2s"
              data-wow-delay=".1s"
            >
            
              <div className="system-icon">
                <img src="assets/images/icon-1.png" alt="Icon" />
              </div>
             
              <div className="system-content">
                <h5 className="title">Exceptional Experience :</h5>
                <p>
                  Delivering a positive experience to customers and candidates
                  in every interaction defines who we are and what we stand for.
                </p>
              </div>
             
            </div>
            <div
              className="system-single wow fadeIn"
              data-wow-duration="1.2s"
              data-wow-delay=".2s"
            >
              <div className="system-icon">
                <img src="assets/images/icon-1.png" alt="Icon" />
              </div>
              <div className="system-content">
                <h5 className="title">Unmatched and Diverse Expertise:</h5>
                <p>
                  Delivering a positive experience to customers and candidates
                  in every interaction defines who we are and what we stand for.
                </p>
              </div>
            </div>
            <div
              className="system-single wow fadeIn"
              data-wow-duration="1.2s"
              data-wow-delay=".3s"
            >
              <div className="system-icon">
                <img src="assets/images/icon-1.png" alt="Icon" />
              </div>
              <div className="system-content">
                <h5 className="title">Partnership Focus :</h5>
                <p>
                  We cultivate long-term relationships that drive your business
                  success.
                </p>
              </div>
            </div>
            <div
              className="system-single wow fadeIn"
              data-wow-duration="1.2s"
              data-wow-delay=".3s"
            >
              <div className="system-icon">
                <img src="assets/images/icon-1.png" alt="Icon" />
              </div>
              <div className="system-content">
                <h5 className="title">Results Driven : </h5>
                <p>
                  We deliver measurable outcomes that align with your goals,
                  ensuring precision, efficiency, and agility in every project
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 ">
          <div className="system-bg2 ">
          
            <div
              className="heading-one wow fadeIn "
              data-wow-duration="1.2s"
              data-wow-delay=".1s"
            >
          
              <h2 className="heading-one-title">
                THE PEN DIFFERENCE–WHY CHOOSE PEN?
              </h2>
            
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </section> */}
        <section>
          <div
            className="why-chhose-us-area section-padding"
            // style={{ marginTop: "15px" }}
          >
            <div className="container">
              <div class="row">
                <div class="col-sm-12">
                  <div class="section-title">
                    <h2>E-Verify Certified</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="why-image">
                    <img src="/assets/images/E-Verify_Logo-2x.png" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    {/* START SINGLE WHY CHOOSE DESIGN AREA */}
                    <div
                      className="col-md-12 col-sm-6 col-xs-12 wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="single-about">
                        {/* <div className="section-sub-title">
                    <h2>E-Verify Certified</h2>
                  </div> */}
                        <p>
                          PEN Software is proud to be an E-Verified company,
                          showcasing our commitment to legal workforce
                          compliance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            className="call-to-action-area"
            data-stellar-background-ratio="0.6"
          >
            <div className="container ">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="section-sub-title text-center">
                    <h2>Looking for Strategic Growth Partner ? </h2>
                  </div>
                  <p className="OurUniverse-aglintext justify">
                    Seeking exceptional project execution or top-tier talent?
                    Look no further.
                  </p>
                  <p className="OurUniverse-aglintext justify">
                    PEN Software is your strategic ally in scaling your business
                    to new heights. Our{" "}
                    <b>'Relationship-Focused Engagement Model'</b> guarantees a
                    personalized, seamless experience tailored to the unique
                    needs of our clients, employees, and consult ants.
                  </p>
                  <div className="text-center">
                    <a
                      className="slide-btn smoth-scroll "
                      style={{ marginTop: "20px" }}
                      href="/Connectwithus"
                    >
                      Contact Us
                    </a>
                  </div>
                  {/* <a href="#" class="read-more white-read-more">Purchase now</a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
export default OuruniverseBody;
