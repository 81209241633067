import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AuthenticationService from "../../../Services/LoginService";
import "./OtpVerification.css";
import Button from "../AdminPenTalk/Button/Button";
import { baseUrl } from "../../../Services/AxiosServices";

const auth = new AuthenticationService();

const OtpVerification = () => {
  // const videoSrc = `${baseUrl}Video/StreamVideo/stream`;

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpStatus, setOtpStatus] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [errors, setErrors] = useState({ errOtp: false });

  const handleOtpChange = (e, index) => {
    e.preventDefault();
    const value = e.target.value.replace(/[^0-9]/g, "");

    if (!username) {
      window.location.href = "/ForgotView";
    }

    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      setErrors({ errOtp: false });

      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          document.getElementById(`otp-${index - 1}`).focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const resendOTP = async () => {
    try {
      setIsLoaded(true);
      const response = await auth.getOtpData(username);

      if (response && response.data > 0) {
        Swal.fire({
          icon: "success",
          title: "OTP Send Succesfully.",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        setTimeout(() => {
          window.location.href = "/OtpVerification";
        }, 3500);
      } else if (response === 0) {
        Swal.fire({
          icon: "error",
          title: "Invalid username",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setError("Invalid username.");
      } else {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Request failed:", error.message);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();

    if (otp.some((digit) => !digit)) {
      setErrors({ errOtp: true });
      return;
    } else {
      setErrors({ errOtp: false });
    }

    setIsLoaded(true);

    const responseBody = {
      userID: 1,
      otp: otp.join(""),
      userEmail: email,
    };

    try {
      const response = await auth.verifyotp(responseBody);

      if (response && response.data === true) {
        setOtpStatus("OTP verified successfully, Password sent to the mail ");

        const id = true;
        window.location.href = `/admin?id=${id}`;
      } else {
        setOtpStatus("Invalid OTP, Please try again.");
        setErrors({ errOtp: true });
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      setOtpStatus("Failed to verify OTP. Please try again.");
      setErrors({ errOtp: true });
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <div className="ps-otpverification">
      {/* <video
        src={videoSrc}
        autoPlay
        muted
        loop
        id="loginbackground-video"
        className="loginbackground-video"
      /> */}
      <img
        src="/assets/images/Background_Image.png"
        id="loginbackground-video"
        className="loginbackground-video"
      />
      <div className="login-container">
        <div className="text-center login-logo ">
          <img
            src="/assets/images/pensoftLogowhite.png"
            alt="Logo"
            className="LogoImg"
          />
        </div>

        <div className="login-custom">
          <div>
            <h5>We have sent an OTP to your email:</h5>

            <div className="form-group otp-inputs">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  className={`form-control otp-field ${
                    errors.errOtp ? "has-error" : ""
                  }`}
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  maxLength="1"
                />
              ))}
            </div>

            {otpStatus && (
              <div className="form-group">
                <span
                  style={{
                    color: otpStatus.includes("successfully") ? "green" : "red",
                  }}
                >
                  {otpStatus}
                </span>
              </div>
            )}

            <div
              className="form-group text-center"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <a className="forgot-password" href="" onClick={resendOTP}>
                Resend OTP
              </a>
              {!isLoaded ? (
                <Button
                  name="Verify OTP"
                  onClick={handleOtpVerification}
                  className="otp-verify-btn"
                />
              ) : (
                <button className="btn btn-success" disabled>
                  <span className="spinner-border spinner-border-sm"></span>{" "}
                  Verifying...
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
