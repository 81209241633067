import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const BreadcrumbHeader = () => {
  const [text, setText] = useState("");
  const [blogtext, setBlogText] = useState("");
  const highlightSegments = [
    "SAPCONSULTING",
    "ORACLECONSULTING",
    "DIGITALTRANSFORMATION",
    "PROFESSIONALSTAFFING",
  ];
  const blogSegments = ["PENTALKDETAILS"];
  const [isExpertiseOpen, setIsExpertiseOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const expertiseDropdownRef = useRef(null); // Ref for the dropdown menu
  const menuRef = useRef(null);

  useEffect(() => {
    let path = window.location.href.split("/");
    let lastSegment = path[path.length - 1];
    let blogSegment = path[path.length - 1];
    let blogCutSegment = blogSegment.split("?")[0];

    setText(lastSegment.toUpperCase());
    setBlogText(blogCutSegment.toUpperCase());
  }, [location]);

  // useEffect(() => {
  //   if (isMenuOpen) {
  //     setIsMenuOpen(false);
  //   }
  // }, [location]);

  const toggleExpertiseDropdown = (e) => {
    e.preventDefault();
    setIsExpertiseOpen(!isExpertiseOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      setIsExpertiseOpen(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        expertiseDropdownRef.current &&
        !expertiseDropdownRef.current.contains(event.target)
      ) {
        setIsExpertiseOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpertiseOpen]);

  return (
    <>
      <div className="header-top-area">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="logo">
                <a href="/">
                  <img
                    className="LogoImg"
                    src="/assets/images/pensoftLogowhite.png"
                    alt="Pensoft Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-9">
              <div className="mainmenu">
                <div className="navbar navbar-nobg">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      onClick={toggleMenu}
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div
                    className={`navbar-collapse collapse ${
                      isMenuOpen ? "in" : ""
                    }`}
                    ref={menuRef}
                    style={{
                      maxHeight: isMenuOpen ? "400px" : "none",
                      overflowY: isMenuOpen ? "auto" : "visible",
                      transition: "max-height 0.3s ease-in-out",
                    }}
                  >
                    <ul className="nav navbar-nav navbar-right">
                      <li className="active">
                        {/* <Link
                          to={"/OurUniverse"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          OUR UNIVERSE
                        </Link> */}
                        <a className="smoth-scroll" href="/OurUniverse">
                          OUR UNIVERSE
                        </a>
                      </li>
                      <li
                        className={`dropdown ${isExpertiseOpen ? "open" : ""}`}
                        ref={expertiseDropdownRef}
                        style={{ position: "relative" }}
                      >
                        <a
                          className="smoth-scroll dropdown-toggle"
                          href="#"
                          data-toggle="dropdown"
                          onClick={toggleExpertiseDropdown}
                        >
                          EXPERTISE
                        </a>
                        {/* <Link
                          className="smoth-scroll dropdown-toggle"
                          href="#"
                          data-toggle="dropdown"
                          onClick={toggleExpertiseDropdown}
                        >
                          EXPERTISE
                        </Link> */}
                        <ul
                          className="dropdown-menu"
                          style={{
                            display: isExpertiseOpen ? "block" : "none",
                          }}
                        >
                          <li className="subMenu">
                            {/* <Link
                              to={"/DigitalTransformation"}
                              // rel="stylesheet"
                              // className="smoth-scroll"
                            >
                              Digital Transformation
                            </Link> */}
                            <a href="/DigitalTransformation">
                              Digital Transformation
                            </a>
                          </li>
                          <li className="subMenu">
                            {/* <Link
                              to={"/OracleConsulting"}
                              // rel="stylesheet"
                              // className="smoth-scroll"
                            >
                              Oracle Consulting
                            </Link> */}
                            <a href="/OracleConsulting">Oracle Consulting</a>
                          </li>
                          <li className="subMenu">
                            {/* <Link
                              to={"/SAPConsulting"}
                              // rel="stylesheet"
                              // className="smoth-scroll"
                            >
                              SAP Consulting
                            </Link> */}
                            <a href="/SAPConsulting">SAP Consulting</a>
                          </li>
                          <li className="subMenu">
                            {/* <Link
                              to={"/ProfessionalStaffing"}
                              // rel="stylesheet"
                              // className="smoth-scroll"
                            >
                              Professional Staffing
                            </Link> */}
                            <a href="/ProfessionalStaffing">
                              Professional Staffing
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        {/* <Link
                          to={"/Excellence"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          EXCELLENCE
                        </Link> */}
                        <a className="smoth-scroll" href="/Excellence">
                          EXCELLENCE
                        </a>
                      </li>
                      <li>
                        {/* <Link
                          to={"/PenTalk"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          PEN TALK
                        </Link> */}
                        <a className="smoth-scroll" href="/PenTalk">
                          PEN TALK
                        </a>
                      </li>
                      <li>
                        {/* <Link
                          to={"/Oppourtunities"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          OPPORTUNITIES
                        </Link> */}
                        <a className="smoth-scroll" href="/Oppourtunities">
                          OPPORTUNITIES
                        </a>
                      </li>
                      <li>
                        {/* <Link
                          to={"/Connectwithus"}
                          rel="stylesheet"
                          className="smoth-scroll"
                        >
                          LET'S CONNECT
                        </Link> */}
                        <a className="smoth-scroll" href="/Connectwithus">
                          LET'S CONNECT
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* END MENU DESIGN AREA */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreadcrumbHeader;
