import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";

import AuthenticationService from "../../../Services/LoginService";
import "./Login.css";
import Button from "../AdminPenTalk/Button/Button";
import { GetCookie, SetCookie } from "../../../common";
import { baseUrl } from "../../../Services/AxiosServices";

const auth = new AuthenticationService();

const LoginView = () => {
  // const imageSrc = `${baseUrl}Images/Banners/Banner_Image.jpg`;

  const [passwordtoggle, setPasswordToggle] = useState(true);
  const [form, setForm] = useState({ Username: "", Password: "" });
  const [dummy, setDummy] = useState({
    errUserName: false,
    errPassword: false,
  });
  const [searchParams] = useSearchParams();
  const category = searchParams.get("id");

  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    if (category) {
      Swal.fire({
        icon: "success",
        title: "OTP Verified, Password sent to the mail",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [category]);

  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const togglePasswordVisibility = () => setPasswordToggle(!passwordtoggle);

  const loginSubmit = async (e) => {
    e.preventDefault();

    let err = { ...dummy };
    let isValid = true;

    if (!form.Username?.trim()) {
      err.errUserName = true;
      isValid = false;
    } else {
      err.errUserName = false;
    }

    if (!form.Password?.trim()) {
      err.errPassword = true;
      isValid = false;
    } else {
      err.errPassword = false;
    }

    setDummy(err);

    if (!isValid) {
      return Swal.fire({
        icon: "error",
        title: "Please fill in all required fields",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    const requestBody = {
      userID: 1,
      username: form.Username.trim(),
      password: form.Password.trim(),
      keepLoggedIn: rememberMe,
    };

    try {
      setIsLoaded(true);
      const response = await auth.getData(requestBody);

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.length > 0
      ) {
        SetCookie("_Token", response.data[0].token, rememberMe ? 30 : 1);
        SetCookie("_UserId", response.data[0].userID, rememberMe ? 30 : 1);
        Swal.fire({
          icon: "success",
          title: "Logged in successfully",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        window.location.href = "/Opportunities-admin";
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid username or password",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Login request failed:", error.message);
      Swal.fire({
        icon: "error",
        title: "Incorrect Username or Password, please try again later",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <div className="ps-login">
      {/* <video
          src={videoSrc}
          autoPlay
          muted
          loop
          id="loginbackground-video"
          className="loginbackground-video"
        /> */}
      <img
        src="/assets/images/Background_Image.png"
        id="loginbackground-video"
        className="loginbackground-video"
      />

      <div className="login-container">
        <div className="text-center login-logo">
          <img
            src="/assets/images/pensoftLogowhite.png"
            alt="Logo"
            className="LogoImg"
          />
        </div>

        <div className="login-custom">
          <form onSubmit={loginSubmit}>
            <div
              className={`form-group  ${dummy.errUserName ? "has-error" : ""}`}
            >
              <label htmlFor="UserName" className="email-label">
                Username
              </label>
              <input
                type="text"
                className="form-control email-input"
                value={form.Username}
                onChange={(e) => setForm({ ...form, Username: e.target.value })}
              />
              {dummy.errUserName && <span className="text-danger"></span>}
            </div>

            <div
              className={`form-group ${dummy.errPassword ? "has-error" : ""}`}
            >
              <label htmlFor="Password" className="email-label">
                Password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={passwordtoggle ? "password" : "text"}
                  className="form-control email-input"
                  value={form.Password}
                  onChange={(e) =>
                    setForm({ ...form, Password: e.target.value })
                  }
                />
                <span
                  className="password-icon"
                  onClick={togglePasswordVisibility}
                >
                  {passwordtoggle ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {dummy.errPassword && <span className="text-danger"></span>}
            </div>

            <div className="Keep-me">
              <input
                className="keepmeremember"
                type="checkbox"
                name="Remember"
                id="rememberMe"
                onChange={handleRememberMeChange}
              />
              <label
                htmlFor="rememberMe"
                style={{
                  marginTop: "8px",
                  marginLeft: "4px",
                  lineHeight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Keep me logged in!
              </label>
              <a
                className="forgot-password"
                style={{ marginLeft: "25%" }}
                href="/ForgotView"
              >
                Forgot password?
              </a>
            </div>

            <div className="form-group text-center">
              {!isLoaded ? (
                <Button
                  name="Login"
                  onClick={loginSubmit}
                  style={{ width: "100%" }}
                />
              ) : (
                <button
                  className="btn btn-success btn-block"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}
            </div>

            {error && <div className="text-danger">{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
